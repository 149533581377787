<template>
<div class="row">
  <div :class="[columnClass]">
    <button class="btn-google social lft"
            :class="{ part : !full }"
            type="button"
            @click="googleAuth()">
      <i class="fab fa-google fa-2x"></i>
      <span>&nbsp;&nbsp;Google</span>
    </button>
  </div>

  <div :class="[columnClass]">
    <button class="btn-fb social mid"
            :class="{ part : !full }"
            type="button"
            v-facebook-signin-button="appId">
      <i class="fab fa-facebook-f fa-2x"></i>
      <span>&nbsp;&nbsp;Facebook</span>
    </button>
  </div>

  <div :class="[columnClass]">
    <button class="btn-twitter social lst"
            :class="{ part : !full }"
            type="button"
            @click="twitterAuth()">
      <i class="fab fa-twitter fa-2x"></i>
      <span>&nbsp;&nbsp;Twitter</span>
    </button>
  </div>
</div>
</template>

<script>
  import FacebookSignInButton from 'vue-facebook-signin-button-directive';
  import { mapActions } from 'vuex';

  export default {
    name  : 'SocialMediaAuth',
    props : {
      full : {
        type    : Boolean,
        default : true,
      },
    },
    data() {
      return {
        appId    : process.env.VUE_APP_FACEBOOK_APP_ID,
        userType : 2,
      };
    },
    directives : {
      FacebookSignInButton,
    },
    computed : {
      columnClass() {
        if (this.full)
          return 'col-md-4';
        return 'col-xs-4 col-md-12 col-xl-4';
      },
    },
    methods : {
      ...mapActions({
        'socialLogin' : 'auth/socialLogin',
      }),

      /**
       * twitter authentication request
       */
      twitterAuth() {
        localStorage.socialRegPath = `/${this.$route.name}`;

        this.$http.post('auth/twitter/reverse')
          .then((response) => {
            localStorage.oauthToken = response.data.oauth_token;
            localStorage.oauthTokenSecret = response.data.oauth_token_secret;
            window.location =
              'https://api.twitter.com/oauth/authenticate?oauth_token=' +
              response.data.oauth_token;
          });
      },

      /**
       * On Facebook authentication successful request
       *
       * @param idToken
       */
      OnFacebookAuthSuccess(idToken) {
        this.$http.post('auth/facebook/token', {
          'access_token' : idToken,
          'userType'     : this.userType,
          'inviterId'    : localStorage.inviterId || 0,
          'birthday'     : localStorage.birthdate,
        }).then((response) => {
          localStorage.socialRegPath = `/${this.$route.name}`;

          // store accessToken and refreshToken
          this.socialLogin({
            accessToken            : response.data.accessToken,
            refreshToken           : response.data.refreshToken,
            expiryAccessTokenUnix  : response.data.expiryAccessTokenUnix,
            expiryAccessTokenDate  : response.data.expiryAccessTokenDate,
            expiryRefreshTokenUnix : response.data.expiryRefreshTokenUnix,
            expiryRefreshTokenDate : response.data.expiryRefreshTokenDate,
          });

          this.$analytics.fbq.event('login-social', {
            'platform'    : 'website',
            'socialMedia' : 'facebook',
          });
        }).catch((e) => {
          this.$store.commit('notification/SET_ERROR', e.response.data.msg);
        });
      },

      
      /**
       * On Facebook authentication failure request
       *
       * @param error
       */
      // eslint-disable-next-line no-unused-vars
      OnFacebookAuthFail(error) {
        // TODO: add on fail logic
      },

      /**
       * Goggle authentication request
       */
      googleAuth() {
        this.$gAuth.signIn()
          .then(user => {
            this.$http.post('auth/google/token', {
              'access_token' : user.wc.access_token,
              'userType'     : this.userType,
              'inviterId'    : localStorage.inviterId || 0,
              'birthday'     : localStorage.birthdate,
            }).then((response) => {
              localStorage.socialRegPath = `/${this.$route.name}`;

              // store accessToken and refreshToken
              this.socialLogin({
                accessToken            : response.data.accessToken,
                refreshToken           : response.data.refreshToken,
                expiryAccessTokenUnix  : response.data.expiryAccessTokenUnix,
                expiryAccessTokenDate  : response.data.expiryAccessTokenDate,
                expiryRefreshTokenUnix : response.data.expiryRefreshTokenUnix,
                expiryRefreshTokenDate : response.data.expiryRefreshTokenDate,
              });
              this.$analytics.fbq.event('login-social', {
                'platform'    : 'website',
                'socialMedia' : 'google',
              });
            }).catch((e) => {
              this.$store.commit('notification/SET_ERROR', e.response.data.msg);
            });
          });
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import '../../assets/scss/components/shared/social-media-auth';

  #login-page {
    .social {
      border: 5px solid #e7932d;
    }
  }
</style>